import React, { useState } from "react";
import { useDispatch } from "react-redux";
// import { useLocation, useNavigate } from "react-router-dom";
import DefaultLayout from "../../components/layout/DefaultLayout";
import TestDetail from "./TestDetail";
import { showLoading, hideLoading } from "../../store/loadingReducer";
import { showAlert } from "../../store/alertReducer";

const Test = () => {
  const dispatch = useDispatch();
  const [detailComponent, setDetailComponent] = useState(null);

  const handleClickDetail = () => {
    setDetailComponent(<TestDetail id={123} />);
  };

  const handleClickShowLoading = () => {
    dispatch(showLoading());
    setTimeout(() => {
      dispatch(hideLoading());
    }, 3000);
  };

  const handleClickShowAlert = () => {
    dispatch(showAlert({ message: "테스트 Alert 입니다." }));
  };

  return (
    <DefaultLayout detail={detailComponent}>
      <div style={{ width: "100%", minWidth: 500, overflowX: "auto" }}>
        List View 입니다.
        <br />
        <button onClick={handleClickDetail}>set Detail</button>
        <br />
        <button onClick={handleClickShowLoading}>show Loading</button>
        <br />
        <button onClick={handleClickShowAlert}>show Alert</button>
      </div>
    </DefaultLayout>
  );
};

export default Test;
