import React from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import styled from "styled-components";
import { Button } from "@progress/kendo-react-buttons";
import DefaultLayout from "../../src/components/layout/DefaultLayout";
// import { useOutletContext } from "react-router-dom";
// import { Window } from "@progress/kendo-react-dialogs";
// import Filters from "../components/layout/Filters";

const initialDataState = {
  skip: 0,
  take: 10,
};

const Flex = styled.div`
  display: "flex";
`;

const FiltersLabel = styled.label`
  margin-left: 10px;
  width: 100px;
`;

const Home = (props) => {
  const [page, setPage] = React.useState(initialDataState);
  const [visible, setVisible] = React.useState(false);

  const pageChange = (event) => {
    setPage(event.page);
  };

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const fetchList = () => {
    // console.log("fetchList");
  };
  return (
    <DefaultLayout detail={<>Detail View 입니다.</>}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: 25,
        }}
      >
        <Grid
          style={{
            width: "100%",
          }}
          scrollable={"none"}
          data={[
            {
              ProductID: 1,
              ProductName: "Chai",
              SupplierID: 1,
              CategoryID: 1,
              QuantityPerUnit: "10 boxes x 20 bags",
              UnitPrice: 18.0,
              UnitsInStock: 39,
              UnitsOnOrder: 0,
              ReorderLevel: 10,
              Discontinued: false,
              Category: {
                CategoryID: 1,
                CategoryName: "Beverages",
                Description: "Soft drinks, coffees, teas, beers, and ales",
              },
            },
          ]}
        >
          <GridColumn field="ProductID" title="ID" width="40px" />
          <GridColumn field="ProductName" title="Name" width="250px" />
          <GridColumn field="Category.CategoryName" title="CategoryName" />
          <GridColumn field="UnitPrice" title="Price" />
          <GridColumn field="UnitsInStock" title="In stock" />
        </Grid>
        <div
          style={{
            marginTop: 10,
            width: "100%",
          }}
        >
          {/* <Filters
            onSearch={fetchList}
            data={[
              {
                label: "조회기간",
                type: "menu",
                data: [
                  { label: "시작일", value: "startDate" },
                  { label: "종료일", value: "endDate" },
                ],
              },
              { label: "재고위치", type: "input" },
            ]}
          /> */}
          <PanelBar>
            <PanelBarItem expanded={true} title={"검색 조건"}>
              <div style={{ padding: "0.5%" }}>
                <Flex style={{ display: "flex" }}>
                  <input type="checkbox" />
                  <FiltersLabel>가입일</FiltersLabel>
                  <Flex>
                    <input type="date" /> ~ <input type="date" />
                  </Flex>
                </Flex>
                <div style={{ display: "flex", marginTop: 10 }}>
                  <input type="checkbox" />
                  <FiltersLabel>검색</FiltersLabel>
                  <input placeholder="이름, 휴대폰" />
                </div>
                <div style={{ display: "flex", marginTop: 10 }}>
                  <input type="checkbox" />
                  <FiltersLabel>보유량</FiltersLabel>
                  <Flex>
                    <select>
                      <option>금</option>
                      <option>은</option>
                      <option>KRW</option>
                    </select>
                    &nbsp; &nbsp;
                    <input /> 이상 <input /> 이하
                  </Flex>
                </div>
                <div style={{ display: "flex", marginTop: 10 }}>
                  <input type="checkbox" />
                  <FiltersLabel>상태</FiltersLabel>
                  <div style={{ display: "contents" }}>
                    <Flex>
                      <input type="radio" />
                      <label>전체</label>
                      &nbsp;
                    </Flex>
                    <Flex>
                      <input type="radio" />
                      <label>활성</label>
                      &nbsp;
                    </Flex>
                    <Flex>
                      <input type="radio" />
                      <label>사용정지</label>
                      &nbsp;
                    </Flex>
                    <Flex>
                      <input type="radio" />
                      <label>휴먼</label>
                      &nbsp;
                    </Flex>
                    <Flex>
                      <input type="radio" />
                      <label>탈퇴</label>
                      &nbsp;
                    </Flex>
                  </div>
                </div>
              </div>
            </PanelBarItem>
          </PanelBar>
          <div
            style={{
              marginTop: 5,
              width: "100%",
            }}
          >
            <Button
              className="buttons-container-button"
              icon="search"
              style={{ marginRight: 5 }}
              onClick={fetchList}
            >
              검색
            </Button>
            <Button
              className="buttons-container-button"
              icon="refresh"
              //   onClick={() => initValue}
            >
              초기화
            </Button>
          </div>
        </div>
        <div
          style={{
            marginTop: 10,
            width: "100%",
          }}
        >
          <Grid
            style={{
              height: "400px",
              cursor: "pointer",
            }}
            data={[
              {
                ProductID: 1,
                ProductName: "Chai",
                SupplierID: 1,
                CategoryID: 1,
                QuantityPerUnit: "10 boxes x 20 bags",
                UnitPrice: 18.0,
                UnitsInStock: 39,
                UnitsOnOrder: 0,
                ReorderLevel: 10,
                Discontinued: false,
                Category: {
                  CategoryID: 1,
                  CategoryName: "Beverages",
                  Description: "Soft drinks, coffees, teas, beers, and ales",
                },
              },
              {
                ProductID: 2,
                ProductName: "Chang",
                SupplierID: 1,
                CategoryID: 1,
                QuantityPerUnit: "24 - 12 oz bottles",
                UnitPrice: 19.0,
                UnitsInStock: 17,
                UnitsOnOrder: 40,
                ReorderLevel: 25,
                Discontinued: false,
                Category: {
                  CategoryID: 1,
                  CategoryName: "Beverages",
                  Description: "Soft drinks, coffees, teas, beers, and ales",
                },
              },
              {
                ProductID: 3,
                ProductName: "Aniseed Syrup",
                SupplierID: 1,
                CategoryID: 2,
                QuantityPerUnit: "12 - 550 ml bottles",
                UnitPrice: 10.0,
                UnitsInStock: 13,
                UnitsOnOrder: 70,
                ReorderLevel: 25,
                Discontinued: false,
                Category: {
                  CategoryID: 2,
                  CategoryName: "Condiments",
                  Description:
                    "Sweet and savory sauces, relishes, spreads, and seasonings",
                },
              },
            ]}
            skip={page.skip}
            take={page.take}
            total={3}
            pageable={true}
            onPageChange={pageChange}
            onRowClick={toggleDialog}
          >
            <GridColumn field="ProductID" />
            <GridColumn field="ProductName" title="Product Name" />
            <GridColumn field="UnitPrice" title="Unit Price" />
          </Grid>
          {/* {visible ? (
            <Window
              title={"Please confirm"}
              onClose={toggleDialog}
              top={0}
              width={"60%"}
              height={"100%"}
              style={{
                right: "0px",
                left: "unset",
              }}
            >
              <form className="k-form">
                <fieldset>
                  <legend>User Details</legend>

                  <label className="k-form-field">
                    <span>First Name</span>
                    <input className="k-input" placeholder="Your Name" />
                  </label>
                  <label className="k-form-field">
                    <span>Last Name</span>
                    <input className="k-input" placeholder="Your Last Name" />
                  </label>
                </fieldset>

                <div className="text-right">
                  <button
                    type="button"
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    onClick={toggleDialog}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                    onClick={toggleDialog}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </Window>
          ) : null} */}
        </div>
      </div>
    </DefaultLayout>

    // <div>
    //   <h3>Inner splitter / left pane</h3>
    //   <p>Resizable and collapsible.</p>
    //   <button>Child 펼치기</button>
    // </div>
    /*{ <div className="pane-content"> }*/
    /*{ <h3>Inner splitter / right pane</h3>
        <p>Resizable only.</p> }*/
    /*{ </div> }*/
  );
};

export default Home;
