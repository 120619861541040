import React from 'react';

const TestDetail = () => {
    return (
        <div>
            Detail View 입니다.      
        </div>
    );
};

export default TestDetail;