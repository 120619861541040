import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import Button from "../../../components/button/Button";
import * as APIS from "../../../libs/apis";
import { showLoading, hideLoading } from "../../../store/loadingReducer";
import { showAlert } from "../../../store/alertReducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const authTimeLeft = 120;

const Reset = ({ account, step, setStep }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [authNo, setAuthNo] = useState("");
  const [isResetOtp, setIsResetOtp] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [timeLeft, setTimeLeft] = useState(authTimeLeft);
  const [stopInterval, setStopInterval] = useState(false);

  const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        tick();
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };

  const handleClickConfirm = () => {
    if (!isResetOtp && !isResetPassword) {
      dispatch(showAlert({ message: "변경할 사항을 선택하여 주세요." }));
    } else if (
      authNo === "" ||
      (isResetPassword && (passwordCheck === "" || password === ""))
    ) {
      dispatch(
        showAlert({ message: "빈값이 있습니다. \n 확인하여 입력하여 주세요." })
      );
    } else if (password !== passwordCheck) {
      dispatch(showAlert({ message: "비밀번호가 일치하지 않습니다." }));
    } else {
      dispatch(showLoading());
      const param = {
        managerAccount: account,
        isResetPassword: isResetPassword,
        isResetOtp: isResetOtp,
        smsVerifyCode: authNo,
        newPassword: passwordCheck,
      };

      APIS.putResetConfirm(param)
        .then(({ data: { success, message } }) => {
          if (success) {
            dispatch(
              showAlert({
                message: "변경완료 되었습니다.",
                onClick: () => {
                  navigate("/login");
                },
              })
            );

            setStopInterval(true);
          } else {
            dispatch(showAlert({ message }));
          }
        })
        .finally(() => dispatch(hideLoading()));
    }
  };
  useInterval(
    () => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      }
    },
    stopInterval ? null : 1000
  );

  useEffect(() => {
    if (timeLeft === 0) {
      dispatch(
        showAlert({
          message: "인증번호가 만료되었습니다.\n 인증번호를 다시 전송해주세요.",
          onClick: () => {
            setStep(step - 1);
          },
        })
      );
    }
  }, [timeLeft]);

  return (
    <div className="input-wrapper">
      <Flex style={{ marginBottom: 10, alignItems: "center" }}>
        <Checkbox
          id="resetOtp"
          name="resetOtp"
          label="OTP 초기화"
          style={{ height: 25, marginTop: 0 }}
          onChange={(e) => {
            setIsResetOtp(e.target.value);
          }}
        />
        <Checkbox
          id="ressetPassword"
          name="ressetPassword"
          label="비밀번호 변경"
          style={{ height: 25, margin: "0px 0px 0px 15px" }}
          onChange={(e) => {
            setIsResetPassword(e.target.value);
          }}
        />
      </Flex>
      <Flex>
        <Input
          name="authNo"
          autoComplete="new-password"
          autoFocus
          required={true}
          value={authNo}
          placeholder={"인증번호"}
          onChange={(e) => {
            setAuthNo(e.target.value);
          }}
          style={{ marginTop: 0 }}
        />
        {timeLeft > -1 && (
          <Time>
            {String(parseInt(timeLeft / 60)).padStart(2, "0")} :{" "}
            {String(timeLeft % 60).padStart(2, "0")}
          </Time>
        )}
      </Flex>
      {isResetPassword && (
        <>
          <div>
            <Input
              name="password"
              type="password"
              autoComplete="new-password"
              value={password}
              placeholder={"password"}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              required={true}
            />
          </div>
          <div>
            <Input
              name="passwordCheck"
              type="password"
              autoComplete="new-password"
              placeholder={"password-check"}
              value={passwordCheck}
              onChange={(e) => {
                setPasswordCheck(e.target.value);
              }}
              required={true}
            />
          </div>
        </>
      )}
      <Flex>
        <Button
          themeColor={"primary"}
          type={"submit"}
          style={{ flex: 1 }}
          onClick={handleClickConfirm}
        >
          확인
        </Button>
      </Flex>
    </div>
  );
};

export default Reset;

const Flex = styled.div`
  display: flex;
`;

const Time = styled.span`
  color: #f80003;
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  display: flex;
  margin-left: -60px;
  z-index: 2;
`;

{
  /* <input
    value={password}
    onChange={(e) => {
      setPassword(e.target.value);
    }}
    placeholder="Password"
    type="password"
    onKeyDown={(e) => {
      if (e.key === "Enter") {
        handleLogin();
      }
    }}
  /> */
}
